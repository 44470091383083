var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.bSkeleton)?_c('skeleton-raw-materials-detail-provider-component'):_c('div',{staticClass:"content-raw-material-detail-provider"},[_c('filter-raw-materials-detail-provider-component',{attrs:{"bAdminProvider":_vm.bAdminProvider},on:{"setSearch":_vm.setSearch}}),(_vm.bEmptyTable)?_c('no-data-text-component-global',{attrs:{"iHeight":0,"sTextEmpty":_vm.sTextEmpty}}):_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"global-table",attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"disable-sort":true,"items":_vm.aTable,"loading":_vm.bLoadingTable,"loading-text":"Cargando datos...","items-per-page":_vm.iItemsPerPage,"hide-default-footer":true,"no-data-text":"No hay información disponible para mostrar.","footer-props":{
      'items-per-page-text': 'Filas por página',
      'items-per-page-all-text': 'Todos',
    }},scopedSlots:_vm._u([{key:"header.sColorKey",fn:function(){return undefined},proxy:true},{key:"item.sColorKey",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"content-color-id-raw-material"},[_c('div',{staticClass:"color-raw-material",style:({
            'background-color': '#' + item.sColorKey + '!important',
          })})])]}},{key:"item.dBasePrice",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.dBasePrice)+" ")]}},{key:"item.dVendorPrice",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.dVendorPrice)+" ")]}},{key:"item.accions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","content-class":"global-tooltip-black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.bAdminProvider),expression:"bAdminProvider"}],staticClass:"global-icon-action",attrs:{"icon":""},on:{"click":function($event){return _vm.setDialogUpdatePriceRawMaterial(item)}}},'v-btn',attrs,false),on),[_c('span',{staticClass:"material-icons"},[_vm._v(" currency_exchange ")])])]}}],null,true)},[_c('span',[_vm._v("Actualizar precio")])])]}},{key:"footer",fn:function(){return [_c('footer-table-component-global',{attrs:{"numPages":_vm.numPages,"totalItems":_vm.totalItems},on:{"changeTable":_vm.getEmitRawMaterial}})]},proxy:true}],null,true)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"var(--primary-color-loading-table)","indeterminate":""}})],1)],2),_c('table-mobile-raw-materials-detail-provider-component',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],attrs:{"aTable":_vm.aTable,"headers":_vm.headers,"numPages":_vm.numPages,"totalItems":_vm.totalItems,"bChangeSelectedItem":_vm.bChangeSelectedItem,"bAdminProvider":_vm.bAdminProvider},on:{"changeTable":_vm.getEmitRawMaterial,"openDetailItem":_vm.setDialogUpdatePriceRawMaterial,"openDeleteItem":_vm.deleteItem,"openUpdatePrice":_vm.setDialogUpdatePriceRawMaterial}}),_c('dialog-update-price-raw-material-global',{attrs:{"bDialogUpdatePriceRawMaterial":_vm.bDialogUpdatePriceRawMaterial,"sApi":_vm.sApiUpdatePrice,"dBasePriceMXN":_vm.dBasePriceMXN,"dBasePriceUSD":_vm.dBasePriceUSD,"dUnitPriceMXN":_vm.dUnitPriceMXN,"dUnitPriceUSD":_vm.dUnitPriceUSD,"itemRawMaterial":_vm.itemRawMaterial,"sLabelBasePrice":"Precio base de compra","sLabelCurrentCustomPrice":"Precio personalizado actual de compra"},on:{"setDialogUpdatePriceRawMaterial":_vm.setDialogUpdatePriceRawMaterial}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }